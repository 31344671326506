<template>
  <div>
    <h1>Keine Partner gefunden...</h1>

    <p>
      Leider konnten wir keine Partner zu den angegebenen Suchkriterien finden. Bitte passen Sie Ihre Suche an oder
      schauen Sie gerne auf unserer Website vorbei:
      <a :href="this.brandWebsiteUrl">{{ this.brandWebsiteUrl }}</a>
    </p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NoSearchResult",
  computed: {
    ...mapGetters([
      "brandWebsiteUrl",
    ]),
  },
};
</script>

<style lang="scss" scoped>
</style>
