<template>
  <div v-if="noSearchNoShops" class="info-panel">
    <h1 v-if="useFormalLanguage">Starten Sie Ihre Suche</h1>
    <h1 v-else>Starte deine Suche</h1>

    <p v-if="useFormalLanguage">Nutzen Sie das Suchfeld und wir zeigen Ihnen Händler in Ihrer Nähe.</p>
    <p v-else>Nutze das Suchfeld und wir zeigen dir Händler in deiner Nähe.</p>
  </div>

  <div v-else-if="shopsLoading" class="info-panel">
    <h1>Lade Partner...</h1>
  </div>

  <div v-else-if="shopListEmpty" class="info-panel">
    <NoSearchResult_kesseboehmer_bathBOX
        v-if="this.noSearchResultSelector === 'kesseboehmer' && isProductBathBox"
    />
    <NoSearchResult_kesseboehmer v-else-if="this.noSearchResultSelector === 'kesseboehmer'"/>
    <NoSearchResult_track v-else-if="this.noSearchResultSelector === 'track'"/>
    <NoSearchResult v-else/>
  </div>

  <ul v-else>
    <li v-for="shop in shops" v-bind:key="shop.id">
      <shop-list-item :shop="shop" :selected="isShopSelected(shop)" v-on:selectedItemChanged="selectedAShop"/>
    </li>
  </ul>
</template>

<script>
import ShopListItem from "./ShopListItem";
import {mapGetters} from "vuex";
import NoSearchResult_track from "./NoSearchResult_track.vue"
import NoSearchResult_kesseboehmer from "./NoSearchResult_kesseboehmer.vue"
import NoSearchResult_kesseboehmer_bathBOX from "./NoSearchResult_kesseboehmer_bathBOX.vue"
import NoSearchResult from "./NoSearchResult.vue";

export default {
  name: "ShopList",
  components: {
    NoSearchResult,
    ShopListItem,
    NoSearchResult_kesseboehmer_bathBOX,
    NoSearchResult_kesseboehmer,
    NoSearchResult_track,
  },
  props: ["shops", "value"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
        "shopsLoading",
        "hasSearchCoordinates",
        "useFormalLanguage",
        "searchCriteria",
        "noSearchResultSelector",
    ]),
    noSearchNoShops() {
      return !this.hasSearchCoordinates && !this.shopsLoading;
    },
    shopListEmpty() {
      return this.shops.length <= 0;
    },
    isProductBathBox() {
      const selectedProduct = this.$store.getters.product(this.searchCriteria.product);

      if (!selectedProduct) {
        return false;
      }

      return selectedProduct.name === "bathBOX"
    }
  },
  methods: {
    isShopSelected(shop) {
      return shop.id === this.value;
    },
    selectedAShop(id) {
      this.$emit('input', id);
    },
    resetSearchCriteria() {
      this.$store.dispatch("resetSearchCriteria");
      this.$store.dispatch("search");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/theme";

ul {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: scroll;

  list-style: none;
}

li {
  border-bottom: $border;
}

li:last-of-type {
  border-bottom: 0;
}

div.info-panel {
  padding: 1.5em;

  h1 {
    margin-top: 0;
  }
}
</style>
