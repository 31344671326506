<template>
  <div id="app">
    <ShopFinder :shops="shops"/>
  </div>
</template>

<script>
import ShopFinder from "./components/ShopFinder";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {
    ShopFinder
  },
  mounted() {
    this.$store.dispatch("loadProducts");
    this.$store.dispatch("initialiseShopList");
  },
  computed: {
    ...mapGetters([
      "apiUrl",
      "shops"
    ])
  }
}
</script>

<style lang="scss" scoped>
@import "styles/theme";

#app {
  max-height: 80%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 6vh auto 4vh;
}

@media (max-width: $breakpoint-small) {
  #app {
    font-size: 0.9em;
    max-height: 100%;
    padding: unset;
    margin: unset;
  }
}
</style>
