<template>
  <div>
    <div class="searchbar">
      <Autocomplete
          :source="searchEndpoint"
          :initial-display="defaultSearchTerm"
          results-value="name"
          results-display="name"
          placeholder="Adresse oder PLZ eingeben..."
          @selected="autoCompleteItemSelected"
          @clear="clearSearch"
      />
    </div>
    <div class="filters">
      <div class="products dropdown">
        <select v-model="product" :class="selectionClasses">
          <option hidden disabled selected :value="null">Händler filtern</option>
          <option value="0">Alle</option>
          <option v-for="productSelectionOption in products" v-bind:key="productSelectionOption.id"
                  :value="productSelectionOption.id"
          >
            {{ productSelectionOption.name }}
          </option>
        </select>
      </div>
      <div class="radius dropdown">
        <select v-model="searchRadius">
          <option v-for="option in searchRadiusOptions" v-bind:key="option.value" :value="option.value">
            {{ option.displayName }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Autocomplete from "vuejs-auto-complete";

export default {
  name: "FilterBar",
  components: {
    Autocomplete,
  },
  data: () => ({
    debounceSearchTerm: null,
    searchTermNew: null,
  }),
  computed: {
    ...mapGetters([
      "apiUrl",
      "searchCriteria",
      "products",
      "defaultSearchTerm",
      "searchRadiusOptionRange",
    ]),
    searchEndpoint() {
      return `${this.apiUrl}/searchAutoCompletion/?term=`;
    },
    searchTerm: {
      get() {
        return this.searchCriteria.term;
      },
    },
    searchRadius: {
      get() {
        return this.searchCriteria.radius;
      },
      set(value) {
        this.$store.commit("updateSearchRadius", value);
        this.$store.dispatch("search");
      }
    },
    searchRadiusOptions() {
      const radiusOptions = [10, 25, 50, 100, 250];

      const {min, max} = this.searchRadiusOptionRange;

      return radiusOptions
          .filter(radius => radius >= min && radius <= max)
          .map(radius => {
            return {value: radius, displayName: `${radius} KM`};
          });
    },
    product: {
      get() {
        return this.searchCriteria.product;
      },
      set(value) {
        this.$store.commit("updateSearchForProduct", value);
        this.$store.dispatch("search");
      }
    },
    selectionClasses() {
      if (this.searchCriteria.product) {
        return [];
      }

      return ["placeholder-value"];
    }
  },
  methods: {
    clearSearch() {
      this.$store.dispatch("clearSearchTerm");
    },
    autoCompleteItemSelected(value) {
      const selectedLocation = {
        name: value.selectedObject.name,
        latitude: value.selectedObject.latitude,
        longitude: value.selectedObject.longitude,
      };

      this.$store.commit("updateSearchLocation", {
        latitude: selectedLocation.latitude,
        longitude: selectedLocation.longitude
      });
      this.$store.dispatch("search");
    }
  }
}
</script>

<style lang="scss">
.searchbar {
  .autocomplete {
    .autocomplete__box {
      border: none;
    }

    .autocomplete__results {
      margin-top: 0.5em;
      border-top: 1px solid #ccc;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "src/styles/theme";

div {
  font-size: 14px;

  .searchbar, .filters {
    border-bottom: $border;
  }

  .searchbar {
    display: flex;
    justify-content: center;
    padding: 1em;
  }

  .filters {
    display: flex;

    .products {
      display: inline-block;
      padding: 1em;
      width: 50%;
    }

    .dropdown {
      display: flex;
      width: 50%;
      padding: 0;

      border-left: $border;

      select {
        z-index: 2;

        display: block;
        border: none;
        appearance: none;
        outline: none;
        cursor: pointer;

        width: 100%;
        height: 100%;
        margin-right: -3.5em;
        padding: 0.5em 1em;

        background-color: transparent;
        color: var(--text-color);
        font-size: 1em;
      }

      select.placeholder-value {
        color: var(--secondary-text-color);
      }

      // Dropdown arrow
      &:after {
        display: flex;
        align-items: center;

        content: '';
        background-image: url("~@/assets/chevron-down.svg");
        background-size: 14px 14px;
        background-repeat: no-repeat;
        background-position: center;
        height: 35px;
        width: 14px;
      }
    }
  }
}
</style>
