<template>
  <div :class="classes" v-on:click="select">
    <div class="fundamental-data">
      <h2>{{ shop.name }}</h2>
      <p>{{ shop.street }}<br>
        {{ shop.zip }} {{ shop.city }}</p>
      <p v-if="shop.phone"><a :href="phoneLink"><i class="fa fa-phone"></i>{{ shop.phone }}</a></p>
      <div class="label-wrapper">
        <p v-if="shop.distance" class="label distance">{{ shop.distance }} entfernt</p>
        <p v-if="shop.is_premium" class="label premium">{{ premiumBadgeText }}</p>
        <a :href="navigationLink" target="_blank" class="navigation-link">Route berechnen</a>
      </div>
    </div>
    <div class="additional-data">
      <a :href="partnerLink(shop.slug)">mehr Infos</a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ShopListItem",
  props: {
    shop: {},
    selected: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    select() {
      this.$emit("selectedItemChanged", this.shop.id);
    }
  },
  computed: {
    ...mapGetters(["partnerLink"]),
    premiumBadgeText() {
      return this.shop.premium_badge_text || "Premium Händler"
    },
    phoneLink() {
      return `tel:${this.shop.phone}`;
    },
    navigationLink() {
      let destination
      if (this.shop.state) {
        destination = `${this.shop.street}, ${this.shop.zip} ${this.shop.city}, ${this.shop.state}, ${this.shop.country}`
      } else {
        destination = `${this.shop.street}, ${this.shop.zip} ${this.shop.city}, ${this.shop.country}`
      }

      return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destination)}`
    },
    classes() {
      const classes = [];

      if (this.selected) {
        classes.push("selected");
      }

      if (this.shop.is_premium) {
        classes.push("premium");
      }

      return classes;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/theme";

div {
  display: flex;
  padding: 1em;

  @media (max-width: $breakpoint-medium) {
    padding: 0.25em;
  }

  @media (max-width: $breakpoint-small) {
    padding: 0.5em;
  }

  &.selected {
    h2 {
      color: var(--accent-color);
    }
  }

  .fundamental-data {
    flex-direction: column;
    padding: 0;
    margin-left: 0.5em;

    h2 {
      font-size: 1.25em;
      margin-top: 0;
      margin-bottom: 0;
    }

    &:hover {
      color: var(--accent-color);
    }

    p {
      font-size: 0.9em;
      margin: 0.5em 0.5em 0.5em 0;
    }

    a > i {
      margin-right: 0.5em;
    }

    a:hover > i {
      color: var(--accent-color);
    }

    .label-wrapper {
      padding: 0;
      & >:not(:first-child) {
        margin-left: 1em;
      }
    }

    .label {
      display: inline-block;
      width: fit-content;
      margin: 0;
      padding: 0.25em 0.75em;
      color: white;

      font-size: 0.75em;

      &.distance {
        background-color: var(--secondary-text-color);
      }

      &.premium {
        background-color: var(--accent-color);
      }
    }

    .navigation-link {
      padding: 0.25em 0;
      font-size: 0.75em;
    }
  }

  &.premium {
    border-left: 0.5em solid var(--accent-color);

    .fundamental-data {
      margin-left: 0;
    }
  }

  .additional-data {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 0;
    min-width: 8em;

    a {
      align-self: center;
      justify-content: flex-end;
    }
  }
}
</style>
