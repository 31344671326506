const axios = require("axios");

export default class Requests {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    getShops() {
        return axios
            .get(`${this.apiUrl}/shops/`)
    }

    getProducts() {
        return axios
            .get(`${this.apiUrl}/products/`)
    }

    search(latitude, longitude, radiusKm, product) {
        let searchParameters = [
            ["radius", radiusKm],
            ["latitude", latitude],
            ["longitude", longitude]
        ];

        if (product && product !== "0") {
            searchParameters.push(["product", product]);
        }

        return axios
            .get(`${this.apiUrl}/search/`, {params: new URLSearchParams(searchParameters)});
    }

    searchAutoCompletion(term) {
        const searchParameters = [
            ["term", term]
        ];

        return axios
            .get(`${this.apiUrl}/searchAutoCompletion/`, {params: new URLSearchParams(searchParameters)});
    }
}
