import Vue from 'vue'
import App from './App.vue'
import createStore from "./storage";

Vue.config.productionTip = false

const appElement = document.querySelector("#app");

new Vue({
    render: h => h(App),
    store: createStore(
        appElement.dataset.baseUrl,
        appElement.dataset.mapBoxApiKey,
        appElement.dataset.mapBoxZoom,
        appElement.dataset.map,
        appElement.dataset.language,
        appElement.dataset.radiusMin,
        appElement.dataset.radiusMax,
        appElement.dataset.noSearchResultSelector,
        appElement.dataset.mapConsentStrategy,
        appElement.dataset.mapConsentValue,
        appElement.dataset.brandWebsiteUrl,
        appElement.dataset.initShops === "true",
        getUrlParameter("product"),
        getUrlParameter("radius"),
        getUrlParameter("term"),
    ),
}).$mount('#app')

function getUrlParameter(name) {
    let urlParameters = new URLSearchParams(window.location.search);

    if (urlParameters.has(name)) {
        return urlParameters.get(name);
    }

    return undefined;
}
