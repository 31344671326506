<template>
  <div id="shopfinder">
    <div class="search-components">
      <FilterBar/>
      <shop-list id="shoplist" :shops="shops" v-model="selected"/>
    </div>

    <div class="map">
      <MglMap
          v-if="mapLoadPermitted()"
          map-style="mapbox://styles/mapbox/light-v10"
          :access-token="mapBoxApiKey"
          :center="center"
          :zoom="mapBoxZoom"
          @load="onMapLoaded"
      >
        <MglNavigationControl position="top-right"/>
        <MglGeolocateControl position="top-right" @geolocate="located"/>
        <MglMarker v-for="shop in shops" v-bind:key="shop.id"
                   :coordinates="[shop.longitude, shop.latitude]" class="mymarker"
                   @added="markerAdded($event, shop)"
        >
          <MglPopup>
            <div class="popup-content">
              <h3>{{ shop.name }}</h3>
              <p>{{ shop.street }}<br>{{ shop.zip }} {{ shop.city }}</p>
              <a :href="partnerLink(shop.slug)" class="button button-primary">zum Händler</a>
            </div>
          </MglPopup>
          <div :class="markerClasses(shop)" slot="marker"/>
        </MglMarker>
      </MglMap>
      <CookieInfo v-else v-on:marketing-cookies-accepted="handleMarketingCookiesAccepted"></CookieInfo>
    </div>
  </div>
</template>

<script>
import ShopList from "./ShopList";
import FilterBar from "./FilterBar";
import {MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup} from "vue-mapbox";
import {mapGetters} from "vuex";
import CookieInfo from "./CookieInfo";
import {getOneTrustCookieGroups} from "../OneTrustCookie";

const singleShopZoom = 10;
const defaultRadius = 9;
const zoomForRadius = {
  10: 11,
  25: 10,
  50: 9,
  100: 8,
  250: 6,
}

export default {
  name: "ShopFinder",
  props: ["shops"],
  components: {
    FilterBar,
    ShopList,
    MglMap,
    MglMarker,
    MglNavigationControl,
    MglGeolocateControl,
    MglPopup,
    CookieInfo,
  },
  data() {
    return {
      center: [11.34888, 51.17961],
      selected: undefined,
      markerRegistry: {},
    }
  },
  created() {
    this.map = null;
    this.mapComponent = null;

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type !== "updateSearchRadius" && mutation.type !== "updateSearchLocation") {
        return
      }

      this.zoomToCurrentSearchArea();
    });

    this.cookieChangeDetectionLoop();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  computed: {
    ...mapGetters([
      "mapBoxApiKey",
      "mapBoxZoom",
      "shop",
      "partnerLink",
      "searchCriteria",
      "mapConsentStrategy",
      "mapConsentValue",
    ]),
  },
  methods: {
    mapLoadPermitted() {
      switch (this.mapConsentStrategy) {
        case "OneTrustCookiePro_Group":
          return getOneTrustCookieGroups()[this.mapConsentValue];
        default:
          return true;
      }
    },
    cookieChangeDetectionLoop() {
      const lastObservedCookie = document.cookie

      setTimeout(() => {
        if (lastObservedCookie !== document.cookie) {
          this.$forceUpdate();
        }

        this.cookieChangeDetectionLoop();
      }, 500);
    },
    markerAdded(event, shop) {
      this.markerRegistry[shop.id] = event.marker;
    },
    closeAllShopPopups() {
      for (const marker of Object.values(this.markerRegistry)) {
        if (marker._popup.isOpen()) {
          marker.togglePopup();
        }
      }
    },
    toggleShopPopup(shop) {
      this.markerRegistry[shop.id].togglePopup();
    },

    onMapLoaded(event) {
      this.map = event.component;
      this.setLanguage(event.map, "de");
      this.$store.dispatch("resolveSearchTerm");
    },
    located(event) {
      let latitude = event.mapboxEvent.coords.latitude;
      let longitude = event.mapboxEvent.coords.longitude;

      this.$store.commit("updateSearchLocation", {
        latitude: latitude,
        longitude: longitude,
      });
      this.$store.dispatch("search");
    },
    setLanguage(map, language) {
      map.setLayoutProperty("country-label", "text-field", ["get", `name_${language}`]);
    },
    markerClasses(shop) {
      const classes = ["marker"]

      if (shop.is_premium) {
        classes.push("premium-partner");
      }

      if (this.selected === shop.id) {
        classes.push("selected");
      }

      return classes;
    },
    zoomToCurrentSearchArea() {
      if (!this.map) {
        return
      }

      const zoom = zoomForRadius[this.searchCriteria.radius] || defaultRadius;

      if (this.searchCriteria.longitude && this.searchCriteria.latitude) {
        this.map.actions.flyTo({
          center: [this.searchCriteria.longitude, this.searchCriteria.latitude],
          zoom: zoom,
          speed: 1
        });
      }
    },
    handleMarketingCookiesAccepted() {
      this.$forceUpdate();
    },
  },
  watch: {
    selected: function (selectedShopId) {
      const shop = this.shop(selectedShopId);
      this.map.actions.flyTo({center: [shop.longitude, shop.latitude], zoom: singleShopZoom, speed: 1})

      this.closeAllShopPopups();
      this.toggleShopPopup(shop);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "src/styles/theme";

#shopfinder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $color-background-light;
  padding: 0;

  display: flex;
  flex-direction: row;

  @media (max-width: $breakpoint-small) {
    flex-direction: column;
    flex-flow: column-reverse;

    border-radius: 0;

    .search-components {
      max-height: 60%;
    }
  }

  & > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  .search-components {
    display: flex;
    flex-direction: column;
  }

  .marker {
    width: 1em;
    height: 1em;
    border-radius: 100%;
    background-color: $color-map-marker;

    cursor: pointer;

    &.premium-partner {
      background: var(--accent-color);
    }

    &.selected {
      background-color: var(--accent-color);
    }
  }

  .popup-content {
    padding: 1em 1em 2em 1em;

    p {
      padding-bottom: 1em;
    }

    .button {
    }
  }
}
</style>
