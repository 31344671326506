export function getOneTrustCookieGroups() {
  const optanonConsentCookieEntry = document.cookie
    .split('; ')
    .find(entry => entry.startsWith("OptanonConsent"));

  if (!optanonConsentCookieEntry) {
    return {};
  }

  const optanonConsentCookieValue = optanonConsentCookieEntry.replace("OptanonConsent=", "");

  const parameters = new URLSearchParams(optanonConsentCookieValue);
  return parameters.get("groups").split(",")
    .map(group => {
      const [groupName, value] = group.split(":")
      return {
        name: groupName,
        enabled: value === "1",
      }
    })
    .reduce((accumulator, group) => {
      const {name, enabled} = group;
      return {
        ...accumulator,
        [name]: enabled,
      };
    }, {});
}
