import Vue from "vue";
import Vuex from "vuex";
import Requests from "./ShopFinderRequests";

Vue.use(Vuex);

function initialSearchCriteria(defaultProduct, defaultRadius) {
    return {
        term: "",
        product: defaultProduct ? parseInt(defaultProduct) : null,
        radius: defaultRadius ? defaultRadius : 50,
        latitude: null,
        longitude: null,
    };
}

const createStore = (
    baseUrl,
    mapBoxApiKey,
    mapBoxZoom,
    map,
    language,
    radiusMin,
    radiusMax,
    noSearchResultSelector,
    mapConsentStrategy,
    mapConsentValue,
    brandWebsiteUrl,
    initialiseShopList,
    defaultProduct,
    defaultRadius,
    defaultTerm,
) => {
    return new Vuex.Store({
        strict: process.env.NODE_ENV !== "production",
        state: {
            shops: [],
            products: [],
            shopsLoading: false,
            search: initialSearchCriteria(defaultProduct, defaultRadius),
            config: {
                baseUrl: baseUrl || process.env.VUE_APP_API_URL,
                mapBoxApiKey: mapBoxApiKey || process.env.VUE_APP_MAPBOX_KEY,
                mapBoxZoom: parseInt(mapBoxZoom) || 5,
            }
        },
        getters: {
            shops: state => {
                return state.shops;
            },
            shop: state => (id) => {
                return state.shops.find(shop => shop.id === id);
            },
            products: state => {
                return state.products;
            },
            product: state => (id) => {
                return state.products.find(product => product.id === id);
            },
            searchCriteria: state => {
                return state.search;
            },
            defaultSearchTerm: () => {
                return defaultTerm;
            },
            hasSearchCoordinates: state => {
                return state.search.latitude && state.search.longitude
            },
            apiUrl: state => {
                return `${state.config.baseUrl}/api`;
            },
            partnerLink: state => (slug) => {
                return `${state.config.baseUrl}/haendler/${slug}`;
            },
            mapBoxApiKey: state => {
                return state.config.mapBoxApiKey;
            },
            mapBoxZoom: state => {
                return state.config.mapBoxZoom;
            },
            mapFilename: () => {
                return `map_${map}.jpg`;
            },
            shopsLoading: state => {
                return state.shopsLoading;
            },
            useFormalLanguage: () => {
                return language === "formal";
            },
            searchRadiusOptionRange: () => {
                return {
                    min: radiusMin || 10,
                    max: radiusMax || 100,
                };
            },
            noSearchResultSelector: () => {
                return noSearchResultSelector;
            },
            mapConsentStrategy: () => {
                return mapConsentStrategy;
            },
            mapConsentValue: () => {
                return mapConsentValue;
            },
            brandWebsiteUrl: () => {
                return brandWebsiteUrl;
            }
        },
        mutations: {
            updateSearchTerm(state, term) {
                state.search.term = term;
            },
            updateSearchRadius(state, radius) {
                state.search.radius = radius;
            },
            updateSearchLocation(state, {latitude, longitude}) {
                state.search.latitude = latitude;
                state.search.longitude = longitude;
            },
            updateSearchForProduct(state, product) {
                state.search.product = product;
            },
            updateShops(state, shops) {
                state.shops = shops;
                state.shopsLoading = false;
            },
            updateProducts(state, products) {
                state.products = products;
            },
            setShopsLoading(state, loading) {
                state.shopsLoading = loading;
            }
        },
        actions: {
            loadProducts({commit, getters}) {
                new Requests(getters.apiUrl).getProducts()
                    .then(response => commit("updateProducts", response.data));
            },
            initialiseShopList({commit, getters}) {
                if (initialiseShopList) {
                    commit("setShopsLoading", true);

                    new Requests(getters.apiUrl).getShops()
                        .then(response => commit("updateShops", response.data.results));
                }
            },
            resolveSearchTerm({getters, commit, dispatch}) {
                if (!defaultTerm) {
                    return
                }

                new Requests(getters.apiUrl).searchAutoCompletion(defaultTerm)
                    .then(response => {
                        if (response.data.length <= 0) {
                            return
                        }

                        const bestResult = response.data[0];
                        commit("updateSearchLocation", {
                            latitude: bestResult.latitude,
                            longitude: bestResult.longitude,
                        });
                        dispatch("search");

                    });
            },
            search({commit, state, getters}) {
                if (getters.hasSearchCoordinates) {
                    commit("setShopsLoading", true);

                    new Requests(getters.apiUrl)
                        .search(state.search.latitude,
                            state.search.longitude,
                            state.search.radius,
                            state.search.product
                        )
                        .then(response => commit("updateShops", response.data));
                }
            },
            clearSearchTerm({commit}) {
                let criteria = initialSearchCriteria(defaultProduct, defaultRadius);
                commit("updateSearchTerm", criteria.term);
                commit("updateSearchLocation", {latitude: null, longitude: null});

                commit("updateShops", []);
            },
            resetSearchCriteria({commit}) {
                let criteria = initialSearchCriteria(defaultProduct, defaultRadius);

                commit("updateSearchTerm", criteria.term);
                commit("updateSearchRadius", criteria.radius);
                commit("updateSearchForProduct", criteria.product);
                commit("updateSearchLocation", {latitude: null, longitude: null});
            },
        }
    });
}

export default createStore;
